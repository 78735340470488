import { Amplify } from 'aws-amplify';
import {
  AWS_COGNITO_USER_POOL_CLIENT_ID,
  AWS_COGNITO_USER_POOL_ID,
  AWS_COGNITO_USER_POOL_PROVIDER_URL,
  AWS_IDENTITY_POOL_ID,
  AWS_REGION,
  AWS_S3_BUCKET_NAME,
  AWS_PINPOINT_APP_ID,
} from './env';
import { configureAutoTrack } from 'aws-amplify/analytics';

export const amplifyConfigure = () => {
  Amplify.configure(
    {
      Auth: {
        Cognito: {
          userPoolId: AWS_COGNITO_USER_POOL_ID,
          userPoolClientId: AWS_COGNITO_USER_POOL_CLIENT_ID,
          userPoolEndpoint: AWS_COGNITO_USER_POOL_PROVIDER_URL,
          identityPoolId: AWS_IDENTITY_POOL_ID,
          allowGuestAccess: true,
        },
      },
      Storage: {
        S3: {
          bucket: AWS_S3_BUCKET_NAME,
          region: AWS_REGION,
        },
      },
      Analytics: {
        Pinpoint: {
          appId: AWS_PINPOINT_APP_ID,
          region: AWS_REGION,
          bufferSize: 100,
          flushInterval: 10000,
          flushSize: 100,
          resendLimit: 10,
        },
      },
    },
    { ssr: true },
  );

  configureAutoTrack({
    enable: true,
    type: 'session',
  });
};
